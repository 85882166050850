<template>
  <div
    class="NavInfo-main"
    :class="{
      w60:
        navIndex.indexOf('/Subject') == -1 &&
        navIndex != '/TrueTopic' &&
        navIndex != '/Search',
    }"
  >
    <div class="NavInfo-left">
      <ul>
        <li>
          <router-link class="navRouter" to="" @click.native="goTo('/home')">
            <div class="navicon navIcon1"></div>
            <div class="navFont">首页</div>
          </router-link>
        </li>
        <!-- <li>
          <router-link class="navRouter" to="/Search" active-class="on">
            <div class="navicon navIcon2"></div>
            <div class="navFont">搜索</div>
          </router-link>
        </li> -->
        <li>
          <router-link
            class="navRouter"
            to=""
            :active-class="activeClass('/Subject')"
            @click.native="goTo('/Subject')"
          >
            <div class="navicon navIcon3"></div>
            <div class="navFont">教学大纲</div>
          </router-link>
        </li>
        <li>
          <router-link
            class="navRouter"
            to=""
            :active-class="activeClass('/Examination')"
            @click.native="goTo('/ExaminationCategory')"
          >
            <div class="navicon navIcon7"></div>
            <div class="navFont">题库</div>
          </router-link>
        </li>
        <!-- <li>
          <router-link class="navRouter" to="/TrueTopic" active-class="on">
            <div class="navicon navIcon7"></div>
            <div class="navFont">历年考卷</div>
          </router-link>
        </li> -->
        <li>
          <router-link
            class="navRouter"
            to=""
            :active-class="activeClass('/Collection')"
            @click.native="goTo('/Collection')"
          >
            <div class="navicon navIcon8"></div>
            <div class="navFont">收藏夹</div>
          </router-link>
        </li>
        <li>
          <router-link
            to=""
            class="navRouter"
            :active-class="activeClass('/WrongQuestion')"
            @click.native="goTo('/WrongQuestion')"
          >
            <div class="navicon navIcon9"></div>
            <div class="navFont">错题集</div>
          </router-link>
        </li>
      </ul>
    </div>
    <div
      class="NavInfo-right"
      v-if="
        navIndex.indexOf('/Subject') != -1 ||
        navIndex == '/TrueTopic' ||
        navIndex == '/Search'
      "
    >
      <div class="NavRightCenter">
        <ul>
          <li v-if="navIndex == '/Search'">
            <div class="NavRightCenter-header">
              <div class="NavShowBut"></div>
              <div class="NavRightCenter-title">搜索</div>
              <div class="h46">
                <button>搜索</button>
                <div>
                  <input type="text" placeholder="请输入搜索内容" />
                </div>
              </div>
            </div>
            <div class="h46dl">
              <dl>
                <dd>第十六章/第六节:长期股权投资与所有者权益的合并处理</dd>
                <dd>第十六章/第六节:长期股权投资与所有者权益的合并处理</dd>
                <dd>第十六章/第六节:长期股权投资与所有者权益的合并处理</dd>
                <dd>第十六章/第六节:长期股权投资与所有者权益的合并处理</dd>
                <dd>第十六章/第六节:长期股权投资与所有者权益的合并处理</dd>
              </dl>
            </div>
          </li>
          <li v-if="navIndex.indexOf('/Subject') != -1">
            <div class="NavRightCenter-header">
              <!--<div class="NavShowBut"></div>
              getCourseTreeByIdFn-->
              <div
                v-if="courselist != null"
                @click="clickCourseTitle"
                class="NavRightCenter-title"
              >
                {{ courselist.title }} 教学大纲
              </div>
              <div v-else class="NavRightCenter-title">&nbsp;</div>
              <div class="h46">
                <button @click="search">搜索</button>
                <div>
                  <input
                    v-model="keyword"
                    type="text"
                    placeholder="请输入搜索内容"
                  />
                </div>
              </div>
              <!-- <div class="NavRightCenter-toggle">
                <div class="toggle-dl">
                  <dl>
                    <dd>上</dd>
                    <dd class="on">关闭</dd>
                  </dl>
                </div>
                <div class="toggleFont">仅显示书签内容</div>
              </div> -->
            </div>
            <div
              v-if="courselist != null && courselist.visible"
              class="navItemShow"
            >
              <div
                v-for="(partItem, partIndex) in courselist.children"
                :key="partIndex"
              >
                <div
                  v-if="partItem.visible"
                  class="NavRightCenter-row lh42"
                  @click="coursePartClickFn(partIndex, partItem)"
                >
                  <i
                    ><img
                      v-if="!partItem.valid && partItem.price != 0"
                      src="~@/assets/icon-js.png"
                    />
                    <div v-else></div>
                  </i>
                  <i
                    ><img
                      v-if="partIndex == coursePartIndex"
                      src="~@/assets/arrow_down.png" />
                    <img v-else src="~@/assets/arrow_right.png"
                  /></i>
                  <div :class="cur('part', partIndex, -1, -1)">
                    {{ partItem.title }}
                  </div>
                </div>
                <div v-if="coursePartIndex == partIndex">
                  <div
                    v-for="(chapterItem, chapterIndex) in partItem.children"
                    :key="chapterIndex"
                  >
                    <div
                      v-if="chapterItem.visible"
                      class="NavRightCenter-row lh36"
                      @click="
                        courseChapterClickFn(
                          partIndex,
                          chapterIndex,
                          chapterItem
                        )
                      "
                    >
                      <i
                        ><img
                          v-if="!chapterItem.valid && chapterItem.price != 0"
                          src="~@/assets/icon-js.png" />
                        <div v-else></div
                      ></i>
                      <i
                        ><img
                          v-if="chapterIndex == courseChapterIndex"
                          src="~@/assets/arrow_down.png" />
                        <img v-else src="~@/assets/arrow_right.png"
                      /></i>
                      <div :class="cur('chapter', partIndex, chapterIndex, -1)">
                        {{ chapterItem.title }}
                      </div>
                    </div>
                    <div v-if="courseChapterIndex == chapterIndex">
                      <!-- v-if="item1.show"-->
                      <dl>
                        <dd
                          v-for="(
                            sectionItem, sectionIndex
                          ) in chapterItem.children"
                          :key="sectionIndex"
                        >
                          <div
                            v-if="sectionItem.visible"
                            class="NavRightCenter-row lh32"
                            @click="
                              courseSectionClickFn(
                                partIndex,
                                chapterIndex,
                                sectionIndex,
                                sectionItem
                              )
                            "
                          >
                            <i
                              ><img
                                v-if="
                                  !sectionItem.valid && sectionItem.price != 0
                                "
                                src="~@/assets/icon-js.png" />
                              <div v-else></div
                            ></i>
                            <i
                              ><img
                                v-if="sectionIndex == courseSectionIndex"
                                src="~@/assets/arrow_down.png" /><img
                                v-else
                                src="~@/assets/arrow_right.png"
                            /></i>

                            <div
                              :class="
                                cur(
                                  'section',
                                  partIndex,
                                  chapterIndex,
                                  sectionIndex
                                )
                              "
                            >
                              {{ sectionItem.title }}
                            </div>
                          </div>
                          <!-- v-if="
                              courseSectionIndex == sectionIndex &&
                              (sectionItem.valid || sectionItem.price == 0)
                            " -->
                          <div v-if="courseSectionIndex == sectionIndex">
                            <!--v-if="item2.show" sectionPermission(sectionIndex, sectionItem) courseSectionIndex == sectionIndex-->
                            <div class="NavRightCenter-dl">
                              <dl>
                                <dd
                                  v-for="(
                                    segmentItem, segmentIndex
                                  ) in sectionItem.children"
                                  :key="segmentIndex"
                                  @click="
                                    courseSegmentClickFn(
                                      partIndex,
                                      chapterIndex,
                                      sectionIndex,
                                      segmentIndex,
                                      segmentItem,
                                      sectionItem
                                    )
                                  "
                                >
                                  <!-- <div
                                    v-for="(item4, f) in item3.segmentsList"
                                    :key="f"
                                    @click="detailsDescription(item4)"
                                  > -->
                                  <div v-if="segmentItem.visible">
                                    <div class="itemTitle">
                                      <div
                                        :class="
                                          naviconitem(
                                            partIndex,
                                            chapterIndex,
                                            sectionIndex,
                                            segmentIndex,
                                            segmentItem.progress
                                          )
                                        "
                                      >
                                        <div class="itemTitleFont">
                                          {{ segmentItem.title }}
                                        </div>
                                        <!-- <div class="itemTime">
                                          {{
                                            parseInt(segmentItem.duration)
                                          }}分钟
                                        </div> -->
                                      </div>
                                    </div>
                                    <!-- practicesItem.progress -->
                                    <div
                                      v-for="(
                                        practicesItem, practiceIndex
                                      ) in segmentItem.children"
                                      :key="practiceIndex"
                                      :class="
                                        practiceSpanClass(segmentItem.progress)
                                      "
                                    >
                                      {{ practicesItem.title }}
                                      <!-- {{ parseInt(practicesItem.duration) }}分钟 -->
                                    </div>
                                    <!-- <div class="itemFont-icon">
                                      <span
                                        v-for="(
                                          practiceLengthItem,
                                          practiceLengtgIndex
                                        ) in segmentItem.children.length"
                                        :key="practiceLengtgIndex"
                                        :class="
                                          practiceSpanClass(
                                            practiceLengthItem.progress
                                          )
                                        "
                                      ></span>
                                    </div> -->
                                  </div>
                                  <!-- </div> -->
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import {
  getCourseTreeById,
  category,
  courseDetails,
  partDetails,
  chapterDetails,
  sectionDetails,
  segmentDetails,
  practicesDetails,
  getCourseTree,
} from "@/api/course";
export default {
  name: "NavInfo",
  components: {},
  props: {
    courseId: {
      type: Number,
      default: -1,
    },
    sectionId: {
      type: Number,
      default: -1,
    },
    chapterId: {
      type: Number,
      default: -1,
    },
    segmentId: {
      type: Number,
      default: -1,
    },
    time: {
      type: Number,
      default: -1,
    },
    kw: {
      type: String,
      default: "",
    },
  },
  watch: {
    sectionId: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.courseType = "SECTION";
        this.showSectionTree();
      },
    },
    segmentId: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.courseType = "SEGMENT";
        this.showSegmentTree();
      },
    },
    chapterId: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.courseType = "CHAPTER";
        this.showChapterTree();
      },
    },
    time: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (this.courseType == "SEGMENT") {
          this.showSegmentTree();
        } else if (this.courseType == "SECTION") {
          this.showSectionTree();
        } else if (this.courseType == "CHAPTER") {
          this.showChapterTree();
        }
      },
    },
  },
  data() {
    return {
      navIndex: this.$route.path,
      courselist: null,
      coursePartIndex: -1,
      courseChapterIndex: -1,
      courseSectionIndex: -1,
      courseSegmentIndex: -1,
      coursePartData: null,
      courseChapterData: null,
      courseSectionData: null,
      courseSegmentData: null,
      keyword: "",
      isSearch: false,
      courseType: "",
    };
  },
  created() {
    this.navFn();
  },
  methods: {
    activeClass(url) {
      if (this.navIndex.indexOf(url) != -1) {
        return "on";
      }
    },
    search() {
      this.coursePartIndex = -1;
      this.courseChapterIndex = -1;
      this.courseSectionIndex = -1;
      this.courseSegmentIndex = -1;
      //this.getCourseTree(0);
      //this.$emit("clearCardList");
      this.searchNode();
      this.$emit("courseList", this.courselist);
      this.$emit("cType", 1);
    },
    detailsDescription(row) {
      //父集传值
      this.$emit("festival", row);
      this.$emit("cType", 2);
    },
    goTo(_url) {
      if (this.navIndex.indexOf("/NewTopic") != -1) {
        this.$emit("setGoToUrl", _url);
      } else {
        this.$router.push({
          path: _url,
        });
      }
    },
    navFn() {
      //点击导航
      if (this.navIndex == "/Search") {
        //搜索
      }
      if (this.navIndex.indexOf("/Subject") != -1) {
        //教学大纲
        this.getCourseTreeByIdFn();
        //this.categoryFn();
        //this.courseFn();
      }
      if (this.navIndex == "/TrueTopic") {
        //往年真题
      }
    },
    coursePartClickFn(partIndex, partItem) {
      this.coursePartIndex = partIndex;
      this.coursePartData = partItem;
      this.courseChapterIndex = -1;
      this.courseSectionIndex = -1;
      this.courseSegmentIndex = -1;
      this.$emit("cType", 1);
      this.$emit("coursePartData", partIndex, partItem);
    },
    sectionPermission(sectionIndex, sectionItem) {
      if (!sectionItem.valid && sectionItem.price != 0) {
        this.$emit("payTip");
        return false;
      } else {
        return this.courseSectionIndex == sectionIndex;
      }
    },
    courseChapterClickFn(partIndex, chapterIndex, chapterItem) {
      this.coursePartIndex = partIndex;
      this.courseChapterIndex = chapterIndex;
      this.courseChapterData = chapterItem;
      this.courseSectionIndex = -1;
      this.courseSegmentIndex = -1;
      this.$emit("cType", 1);
      this.$emit("courseChapterData", partIndex, chapterIndex, chapterItem);
    },
    courseSectionClickFn(partIndex, chapterIndex, sectionIndex, sectionItem) {
      this.coursePartIndex = partIndex;
      this.courseChapterIndex = chapterIndex;
      this.courseSectionIndex = sectionIndex;
      this.courseSegmentIndex = -1;
      this.courseSectionData = sectionItem;
      this.$emit("cType", 1);
      this.$emit(
        "courseSectionData",
        partIndex,
        chapterIndex,
        sectionIndex,
        sectionItem
      );
    },
    courseSegmentClickFn: debounce(
      function (
        partIndex,
        chapterIndex,
        sectionIndex,
        segmentIndex,
        segmentItem,
        sectionItem
      ) {
        this.coursePartIndex = partIndex;
        this.courseChapterIndex = chapterIndex;
        this.courseSectionIndex = sectionIndex;
        this.courseSegmentIndex = segmentIndex;
        this.courseSegmentData = segmentItem;
        if (!sectionItem.valid && sectionItem.price != 0) {
          this.$emit('payTip');
          return;
        }
        this.$emit('cType', 2);
        this.$emit(
          'courseSegmentData',
          partIndex,
          chapterIndex,
          sectionIndex,
          segmentIndex,
          segmentItem
        );
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    ),
    async getCourseTree(type) {
      // const { code, data } = await getCourseTree({
      //   keyword: this.keyword,
      //   courseId: this.courseId,
      // });
      const { code, data } = await getCourseTreeById(this.courseId);
      if (code == 20000) {
        this.courselist = data;
        if (this.keyword != "") {
          this.searchNode();
        } else {
          this.initCourseList();
        }
        if (type == 0) {
          this.$emit("courseList", data);
          this.$emit("cType", 1);
        } else if (type == 2) {
          //表示订购成功updateCourseList
          this.$emit("updateCourseList", data);
        }
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    clickCourseTitle() {
      this.sectionId = -1;
      this.segmentId = -1;
      this.chapterId = -1;
      if (this.courseId != -1) {
        this.$cookies.set("COURSE_" + this.courseId, "", "30d");
      }
      this.isSearch = false;
      this.$emit("setSearch", false);
      this.getCourseTreeByIdFn();
    },
    async getCourseTreeByIdFn() {
      if (this.courseId == -1) {
        return;
      }
      this.keyword = this.kw;
      this.coursePartIndex = -1;
      this.courseChapterIndex = -1;
      this.courseSectionIndex = -1;
      this.courseSegmentIndex = -1;
      const { code, data } = await getCourseTreeById(this.courseId);
      if (code == 20000) {
        this.courselist = data;
        this.initCourseList();
        this.$emit("courseList", this.courselist);
        this.$emit("cType", 1);
        if (this.keyword != "") {
          this.searchNode();
          this.$emit("courseList", this.courselist);
          this.$emit("cType", 1);
        }
        var isFlag = false;
        if (this.sectionId != -1) {
          isFlag = true;
          this.showSectionTree();
        }
        if (this.segmentId != -1) {
          isFlag = true;
          this.showSegmentTree();
        }
        if (this.chapterId != -1) {
          isFlag = true;
          this.showChapterTree();
        }
        // if (this.segmentId != -1) {
        //   isFlag = true;
        //   let partLength = this.courselist.children.length;
        //   for (let i = 0; i < partLength; i++) {
        //     let chapterLength = this.courselist.children[i].children.length;
        //     for (let j = 0; j < chapterLength; j++) {
        //       let sectionLength =
        //         this.courselist.children[i].children[j].children.length;
        //       for (let k = 0; k < sectionLength; k++) {
        //         let segmentLength =
        //           this.courselist.children[i].children[j].children[k].children
        //             .length;
        //         for (let l = 0; l < segmentLength; l++) {
        //           if (
        //             this.courselist.children[i].children[j].children[k]
        //               .children[l].id == this.segmentId
        //           ) {
        //             this.courseSegmentClickFn(
        //               i,
        //               j,
        //               k,
        //               l,
        //               this.courselist.children[i].children[j].children[k]
        //                 .children[l],
        //               this.courselist.children[i].children[j].children[k]
        //             );
        //             return;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
        if (!isFlag) {
          let key = "COURSE_" + this.courseId;
          var coursePartIndex = -1;
          var courseChapterIndex = -1;
          var courseSectionIndex = -1;
          var courseSegmentIndex = -1;
          if (
            this.$cookies.isKey(key) &&
            this.$cookies.get(key) != "" &&
            this.$cookies.get(key) != null
          ) {
            let focusJson = this.$cookies.get(key);
            let partLength = this.courselist.children.length;
            for (let i = 0; i < partLength; i++) {
              if (
                parseInt(focusJson.partId) == this.courselist.children[i].id
              ) {
                coursePartIndex = i;
                let chapterLength = this.courselist.children[i].children.length;
                for (let j = 0; j < chapterLength; j++) {
                  if (
                    parseInt(focusJson.chapterId) ==
                    this.courselist.children[i].children[j].id
                  ) {
                    courseChapterIndex = j;
                    let sectionLength =
                      this.courselist.children[i].children[j].children.length;
                    for (let k = 0; k < sectionLength; k++) {
                      if (
                        parseInt(focusJson.sectionId) ==
                        this.courselist.children[i].children[j].children[k].id
                      ) {
                        courseSectionIndex = k;
                        let segmentLength =
                          this.courselist.children[i].children[j].children[k]
                            .children.length;
                        for (let l = 0; l < segmentLength; l++) {
                          if (
                            this.courselist.children[i].children[j].children[k]
                              .children[l].id == parseInt(focusJson.segmentId)
                          ) {
                            courseSegmentIndex = l;
                            this.courseSegmentClickFn(
                              i,
                              j,
                              k,
                              l,
                              this.courselist.children[i].children[j].children[
                                k
                              ].children[l],
                              this.courselist.children[i].children[j].children[
                                k
                              ]
                            );
                            return;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (
            coursePartIndex != -1 &&
            courseChapterIndex != -1 &&
            courseSectionIndex != -1
          ) {
            this.courseSectionClickFn(
              coursePartIndex,
              courseChapterIndex,
              courseSectionIndex,
              this.courselist.children[coursePartIndex].children[
                courseChapterIndex
              ].children[courseSectionIndex]
            );
          } else if (coursePartIndex != -1 && courseChapterIndex != -1) {
            this.courseChapterClickFn(
              coursePartIndex,
              courseChapterIndex,
              this.courselist.children[coursePartIndex].children[
                courseChapterIndex
              ]
            );
          } else if (coursePartIndex != -1) {
            this.coursePartClickFn(
              coursePartIndex,
              this.courselist.children[coursePartIndex]
            );
          }
        }
        //}
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    initCourseList() {
      this.$set(this.courselist, "visible", true);
      this.initValidNode(this.courselist.children, true);
      //console.log(JSON.stringify(this.courselist));
    },
    initValidNode(node, bool) {
      let length = node.length;
      for (let i = 0; i < length; i++) {
        this.$set(node[i], "visible", bool);
        if (node[i].children && node[i].children.length > 0) {
          this.initValidNode(node[i].children, bool);
        }
      }
    },
    searchNode() {
      if (this.keyword == "") {
        this.courselist.visible = true;
        this.initValidNode(this.courselist.children, true);
        this.$emit("courseList", this.courselist);
        this.$emit("cType", 1);
        this.$emit("setSearch", false);
        this.isSearch = false;
        return;
      }
      this.isSearch = true;
      this.$emit("setSearch", true);
      this.courselist.visible = false;
      this.initValidNode(this.courselist.children, false);
      let partLength = this.courselist.children.length;
      //解决关键词过滤翻页的话需要加json重新组装成一个树型结构
      for (let i = 0; i < partLength; i++) {
        //篇
        let chapterLength = this.courselist.children[i].children.length;
        for (let j = 0; j < chapterLength; j++) {
          //章
          let sectionLength =
            this.courselist.children[i].children[j].children.length;
          for (let k = 0; k < sectionLength; k++) {
            //节
            let segmentLength =
              this.courselist.children[i].children[j].children[k].children
                .length;
            let sectiontVisible = -1;
            if (
              this.courselist.children[i].children[j].children[k].points != null
            ) {
              sectiontVisible = this.courselist.children[i].children[
                j
              ].children[k].points.indexOf(this.keyword);
            }
            if (sectiontVisible > -1) {
              this.courselist.children[i].children[j].children[
                k
              ].visible = true;
              this.courselist.children[i].children[j].visible = true;
              this.courselist.children[i].visible = true;
              this.courselist.visible = true;
            }
            for (let l = 0; l < segmentLength; l++) {
              //段
              if (sectiontVisible > -1) {
                this.courselist.children[i].children[j].children[k].children[
                  l
                ].visible = true;
              } else {
                let segmentVisible = -1;
                if (
                  this.courselist.children[i].children[j].children[k].children[
                    l
                  ].points != null
                ) {
                  segmentVisible = this.courselist.children[i].children[
                    j
                  ].children[k].children[l].points.indexOf(this.keyword);
                }
                if (segmentVisible > -1) {
                  this.courselist.children[i].children[j].children[k].children[
                    l
                  ].visible = true;
                  this.courselist.children[i].children[j].children[
                    k
                  ].visible = true;
                  this.courselist.children[i].children[j].visible = true;
                  this.courselist.children[i].visible = true;
                  this.courselist.visible = true;
                }
              }
            }
          }
        }
      }
      //if (this.courselist.visible) {

      //}
    },
    showSectionTree() {
      let partLength = this.courselist.children.length;
      for (let i = 0; i < partLength; i++) {
        let chapterLength = this.courselist.children[i].children.length;
        for (let j = 0; j < chapterLength; j++) {
          let sectionLength =
            this.courselist.children[i].children[j].children.length;
          for (let k = 0; k < sectionLength; k++) {
            if (
              this.courselist.children[i].children[j].children[k].id ==
              this.sectionId
            ) {
              this.courseSectionClickFn(
                i,
                j,
                k,
                this.courselist.children[i].children[j].children[k]
              );
              return;
            }
          }
        }
      }
    },
    showChapterTree() {
      let partLength = this.courselist.children.length;
      for (let i = 0; i < partLength; i++) {
        let chapterLength = this.courselist.children[i].children.length;
        for (let j = 0; j < chapterLength; j++) {
          if (this.courselist.children[i].children[j].id == this.chapterId) {
            this.courseChapterClickFn(
              i,
              j,
              this.courselist.children[i].children[j]
            );
            return;
          }
        }
      }
    },
    showSegmentTree() {
      let partLength = this.courselist.children.length;
      for (let i = 0; i < partLength; i++) {
        let chapterLength = this.courselist.children[i].children.length;
        for (let j = 0; j < chapterLength; j++) {
          let sectionLength =
            this.courselist.children[i].children[j].children.length;
          for (let k = 0; k < sectionLength; k++) {
            let segmentLength =
              this.courselist.children[i].children[j].children[k].children
                .length;
            for (let l = 0; l < segmentLength; l++) {
              if (
                this.courselist.children[i].children[j].children[k].children[l]
                  .id == this.segmentId
              ) {
                this.courseSegmentClickFn(
                  i,
                  j,
                  k,
                  l,
                  this.courselist.children[i].children[j].children[k].children[
                    l
                  ],
                  this.courselist.children[i].children[j].children[k]
                );
                return;
              }
            }
          }
        }
      }
    },
    async categoryFn() {
      // 课程分类
      const { code, data } = await category();
      if (code == 20000) {
        var _this = this;
        data &&
          data.map((r) => {
            _this.$set(r, "show", false);
          });
        _this.list = data;
        this.$emit("courseList", data && data.length > 0 ? data[0] : undefined);
        this.$emit("cType", 1);
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    courseFn(row) {
      //点击分类获取课程
      var _this = this;
      if (row.show) {
        _this.$set(row, "show", false);
      } else {
        _this.$set(row, "subsetList", []);
        _this.courseDetailsFn(row);
      }
      _this.$emit("courseList", row);
      _this.$emit("cType", 1);
    },
    async courseDetailsFn(row) {
      //获取课程
      //const { code, data } = await courseDetails(row.id);
      const { code, data } = await courseDetails(this.courseId);
      if (code == 20000) {
        var _this = this;
        _this.$set(row, "show", true);
        data &&
          data.parts &&
          data.parts.map((r) => {
            _this.$set(r, "show", false);
          });
        row.subsetList = data.parts;
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    course1Fn(row) {
      //点击课程获取课程篇
      var _this = this;
      if (row.show) {
        _this.$set(row, "show", false);
      } else {
        _this.$set(row, "chaptersList", []);
        this.partDetailsFn(row);
      }
    },
    async partDetailsFn(row) {
      //获取课程篇
      const { code, data } = await partDetails(row);
      var _this = this;
      if (code == 20000) {
        _this.$set(row, "show", true);
        data &&
          data.chapters &&
          data.chapters.map((r) => {
            _this.$set(r, "show", false);
          });
        _this.$set(row, "chaptersList", data.chapters);
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    course2Fn(row) {
      //点击课程篇获取章
      var _this = this;
      if (row.show) {
        _this.$set(row, "show", false);
      } else {
        _this.sectionsFn(row);
      }
    },
    async sectionsFn(row) {
      //获取获取章
      const { code, data } = await chapterDetails(row);
      var _this = this;
      if (code == 20000) {
        _this.$set(row, "show", true);
        _this.$set(row, "sectionsList", data.sections);
        data.sections &&
          data.sections.map((r) => {
            _this.sectionDetailsFn(r);
          });
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    async sectionDetailsFn(row) {
      //获取获取节
      const { code, data } = await sectionDetails(row);
      var _this = this;
      if (code == 20000) {
        _this.$set(row, "show", true);
        _this.$set(row, "segmentsList", data.segments);
        data.segments &&
          data.segments.map((r) => {
            _this.segmentDetailsFn(r);
            _this.practicesDetailsFn(r);
          });
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },

    async segmentDetailsFn(row) {
      //获取段
      const { code, data } = await segmentDetails(row);
      var _this = this;
      if (code == 20000) {
        _this.$set(row, "show", true);
        // _this.$set(row,'segmentsList',data.segments)
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },

    async practicesDetailsFn(row) {
      //获取段
      const { code, data } = await practicesDetails(row);
      var _this = this;
      if (code == 20000) {
        _this.$set(row, "show", true);
        data &&
          data.map((r) => {
            _this.$set(r, "show", false);
          });
        _this.$set(row, "practicesList", data);
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    naviconitem(partIndex, chapterIndex, sectionIndex, segmentIndex, progress) {
      if (progress >= 100) {
        if (
          this.coursePartIndex == partIndex &&
          this.courseChapterIndex == chapterIndex &&
          this.courseSectionIndex == sectionIndex &&
          this.courseSegmentIndex == segmentIndex
        ) {
          return "naviconitem on click";
        } else {
          return "naviconitem on";
        }
      } else {
        if (
          this.coursePartIndex == partIndex &&
          this.courseChapterIndex == chapterIndex &&
          this.courseSectionIndex == sectionIndex &&
          this.courseSegmentIndex == segmentIndex
        ) {
          return "naviconitem click";
        } else {
          return "naviconitem";
        }
      }
    },
    cur(type, partIndex, chapterIndex, sectionIndex) {
      if (
        type == "part" &&
        partIndex == this.coursePartIndex &&
        this.courseChapterIndex == -1 &&
        this.courseSectionIndex == -1 &&
        this.courseSegmentIndex == -1
      ) {
        return "cur click";
      } else if (
        type == "chapter" &&
        partIndex == this.coursePartIndex &&
        this.courseChapterIndex == chapterIndex &&
        this.courseSectionIndex == -1 &&
        this.courseSegmentIndex == -1
      ) {
        return "cur click";
      } else if (
        type == "section" &&
        partIndex == this.coursePartIndex &&
        this.courseChapterIndex == chapterIndex &&
        this.courseSectionIndex == sectionIndex &&
        this.courseSegmentIndex == -1
      ) {
        return "cur click";
      } else {
        return "cur";
      }
    },
    practiceSpanClass(progress) {
      if (progress >= 100) {
        return "itemFont on";
      } else {
        return "itemFont";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.NavInfo-main {
  position: fixed;
  background: #f8f8f8;
  height: 100vh;
  width: 410px;
  left: 0;
  top: 0;
  padding-top: 60px;
  box-sizing: border-box;
  box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.16);
  &.w60 {
    width: 60px;
  }
  .NavInfo-left {
    float: left;
    width: 60px;
    height: 100%;
    background: #434343;
    ul {
      li {
        text-align: center;
        .navRouter {
          cursor: pointer;
          height: 80px;
          box-sizing: border-box;
          padding: 13px 0;
          display: block;
        }
        .navicon {
          margin: 0 auto;
          height: 32px;
          width: 32px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 32px;
          &.navIcon1 {
            background-image: url("~@/assets/nav-icon-1.png");
          }
          &.navIcon2 {
            background-image: url("~@/assets/nav-icon-2.png");
          }
          &.navIcon3 {
            background-image: url("~@/assets/nav-icon-3.png");
          }
          &.navIcon4 {
            background-image: url("~@/assets/nav-icon-4.png");
          }
          &.navIcon5 {
            background-image: url("~@/assets/nav-icon-5.png");
          }
          &.navIcon6 {
            background-image: url("~@/assets/nav-icon-6.png");
          }
          &.navIcon7 {
            background-image: url("~@/assets/nav-icon-7.png");
          }
          &.navIcon8 {
            background-image: url("~@/assets/nav-icon-8.png");
          }
          &.navIcon9 {
            background-image: url("~@/assets/nav-icon-9.png");
          }
        }
        .navFont {
          color: #ffffff;
          font-size: 12px;
          line-height: 24px;
        }
        .on,
        a:hover {
          background-color: #ffcf00;
          .navicon {
            &.navIcon1 {
              background-image: url("~@/assets/nav-icon-1a.png");
            }
            &.navIcon2 {
              background-image: url("~@/assets/nav-icon-2a.png");
            }
            &.navIcon3 {
              background-image: url("~@/assets/nav-icon-3a.png");
            }
            &.navIcon4 {
              background-image: url("~@/assets/nav-icon-4a.png");
            }
            &.navIcon5 {
              background-image: url("~@/assets/nav-icon-5a.png");
            }
            &.navIcon6 {
              background-image: url("~@/assets/nav-icon-6a.png");
            }
            &.navIcon7 {
              background-image: url("~@/assets/nav-icon-7a.png");
            }
            &.navIcon8 {
              background-image: url("~@/assets/nav-icon-8a.png");
            }
            &.navIcon9 {
              background-image: url("~@/assets/nav-icon-9a.png");
            }
          }
          .navFont {
            color: #333333;
          }
        }
      }
    }
  }
  .NavInfo-right {
    padding-left: 60px;
    width: 100%;
    box-sizing: border-box;
    .NavRightCenter {
      overflow: auto;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
.NavRightCenter-header {
  padding: 20px;
  position: relative;
  background: #f8f8f8;
  border-bottom: 1px solid #707070;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);
  .NavShowBut {
    width: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    height: 30px;
    background: url("~@/assets/navShowIcon1.png") no-repeat center;
    background-size: cover;
    &:hover {
      background: url("~@/assets/navShowIcon2.png") no-repeat center;
      background-size: cover;
    }
  }
  .NavRightCenter-title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 32px;
    color: #060606;
    font-weight: bold;
    padding-top: 15px;
    cursor: pointer;
  }
  .NavRightCenter-toggle {
    margin-top: 15px;
    height: 36px;
    line-height: 36px;
    .toggle-dl {
      margin: 0;
      padding: 0;
      height: 36px;
      background: #ffffff;
      border: 1px solid #2e2e2e;
      float: left;
      overflow: hidden;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 0;
      dd {
        float: left;
        padding: 0 12px;
        font-size: 12px;
        font-weight: 400;
        color: #575b66;
        line-height: 35px;
        cursor: pointer;
        min-width: 50px;
        text-align: center;
        &.on {
          background: #262626;
          color: #ffffff;
        }
      }
    }
    .toggleFont {
      float: left;
      line-height: 36px;
      margin-left: 12px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #060606;
    }
  }
}
.NavRightCenter-row {
  padding: 15px 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #060606;
  border-bottom: 1px solid #707070;
  &.lh42 {
    line-height: 42px;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0px 5px 6px 1px rgba(0, 0, 0, 0.16);
  }
  &.lh36 {
    font-size: 18px;
    line-height: 36px;
    font-weight: bold;
  }
  &.lh32 {
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
  }
  div {
    padding-right: 30px;
  }

  i {
    float: right;
    img {
      height: 24px;
      width: 24px;
      cursor: pointer;
      margin-top: 6px;
    }
    div {
      height: 24px;
      width: 24px;
      padding-right: 0px;
      margin-top: 6px;
    }
  }
}
.NavRightCenter-item {
  padding: 10px 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #060606;

  border-bottom: 1px solid #707070;
  .itemFl {
    float: left;
  }
  .itemFr {
    float: right;
    height: 44px;
    width: 44px;
    text-align: center;
    line-height: 44px;
    font-size: 12px;
  }

  &::after {
    display: block;
    content: "";
    clear: both;
  }
  .itemTime {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    color: #575b66;
  }
}
.NavRightCenter-dl {
  .itemTitle {
    // border-bottom: 1px solid #707070;
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
      color: #173d7c;
    }
    .naviconitem {
      background: url("~@/assets/naviconitem-1.png") no-repeat center left;
      padding-left: 40px;
      background-size: 24px;
      &.on {
        background: url("~@/assets/naviconitem-2.png") no-repeat center left;
        padding-left: 40px;
        background-size: 24px;
      }
      &.click {
        //color: #ffcf00;
        //color: #173d7c;
        color: #1890ff;
      }
    }
    .itemTitleFont {
      line-height: 36px;
      font-size: 16px;
    }
    .itemTime {
      font-size: 14px;
    }
  }
  .itemFont {
    // border-bottom: 1px solid #707070;
    padding: 10px 20px 10px 80px;
    background: url("~@/assets/naviconitem-3.png") no-repeat 50px center;
    background-size: 18px;
    color: #5d5d5d;
    cursor: pointer;
    &:hover {
      color: #173d7c;
    }
    &.on {
      background: #dddddd;
      background: url("~@/assets/naviconitem-4.png") no-repeat 50px center;
      background-size: 18px;
      color: #5d5d5d;
      cursor: pointer;
    }
    .itemFont-icon {
      span {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-right: 5px;
        border-radius: 50%;
        border: 1px solid #dddddd;
        &.on1 {
          background: #dddddd;
        }
      }
    }
  }
}
.cur {
  cursor: pointer;
  //color: #1890ff;
  &.click {
    //color: #ffcf00;
    //color: #173d7c;
    color: #1890ff;
  }
}
.navItemShow {
  //height: calc(100vh - 148px + 10px);
  height: calc(100vh - 220px);
  overflow: auto;
}
.h46 {
  height: 46px;
  margin-top: 15px;
  button {
    float: right;
    height: 46px;
    background: #5a5a5d;
    border-radius: 4px 4px 4px 4px;
    color: #ffffff;
    border: 0;
    cursor: pointer;
    font-size: 16px;
    padding: 0 15px;
    &:hover {
      background: #173d7c;
    }
  }
  div {
    padding-right: 70px;
    input[type="text"] {
      height: 46px;
      background: #ffffff url("~@/assets/sosuuo.png") no-repeat 10px center;
      background-size: 16px;
      border: 1px solid #707070;
      border-radius: 4px;
      font-size: 16px;
      padding: 0 10px;
      width: 100%;
      padding-left: 36px;
    }
  }
}
.h46dl {
  margin-top: 15px;
  dl {
    dd {
      font-size: 16px;
      font-weight: 400;
      color: #173d7c;
      line-height: 26px;
      padding: 10px 30px;
    }
  }
}
</style>
